

<!-- 实施顾问 -->
<template>
  <div class="workIssue">
    <product-banner :bannerImg="bannerImg" :bannerTitleData="bannerTitleData" />
    <!-- 应用场景 -->
    <div class="workIssue-apps" style="margin-bottom:60px">
      <div class="banxin">
        <div class="apps-container">
          <div class="banxin">
            <Subhead :enVal="'Implementation consultant'" :znVal="'实施顾问'" :isBlue="1" />
            <div class="apps-of-img">
              <div class="apps-text">{{ appsData.text | myTrim}}
              </div>
              <img class="apps-img" :src="appsData.imgUrl" alt="">
            </div>
          </div>
          <div class="blueBg">
            <div class="blueBg-container">
              <div class="left">
                <div class="zn">想了解更多关于宝晟？</div>
                <div class="en"> Bioshine?</div>
              </div>
              <img :src="AllowImg" alt="" class="right">
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import productBanner from './components/ProductsBanner.vue'
import Subhead from "@/views/cases/components/Subheading.vue"
export default {
  components: {
    productBanner,
    Subhead
  },
  data() {
    return {
      bannerImg: require("@/assets/images/products/products-workIssue.png"),
      bannerTitleData: {
        zn: "实施顾问",
        en: "Consulting & Solutions"
      },
      appsData: {
        text: "宝晟（广州）生物信息技术有限公司专注于为生物技术、生物医药行业、生态环境用户提供样本资源和实验室业务的流程和数据管理信息化解决方案，公司积累了丰富的行业信息化建设经验，实施顾问团队结合相应的法规、标准，为用户梳理、重建业务流程，构建数据中心，通过对业务进行精细化、合规化管理，协助用户提升在科研和生产领域的管理、决策和创新水平，站在用户所在行业领域的前沿，为用户提供可持续发展的信息化建设方式和途径。实施顾问专家来自中国医药生物技术协会组织生物样本库分会，国家计算机网络应急技术处理协调中心，中国科学院昆明动物研究所，中山大学化学学院，广州中医院大学附属第二医院，广州医科大学肿瘤研究所，广东农业科学院资源环境研究所、广东省环境监测中心，深圳国家基因库、中山康方生物科技有限公司等。",
        imgUrl: require("@/assets/images/cases/content/apps-bioBank01.png")
      },
      AllowImg: require("@/assets/images/cases/content/apps-bg-allow.png"),
    }

  }
}
</script>

    <style lang="less" scoped>
    .apps-container {
      padding-top: 86px;
      position: relative;
    
      .apps-of-img {
        display: flex;
        justify-content: space-between;
        // margin-top: 67px;
    
        .apps-text {
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          line-height: 37px;
          letter-spacing: 3px;
          margin-top: 62px;
          text-align: justify;
    
          .know-more {
            width: 136px;
            height: 44px;
            margin-top: 36px;
            background: #2A5CE9;
            text-align: center;
            line-height: 44px;
            font-size: 18px;
            font-weight: 400;
            color: #FFFFFF;
            border-radius: 30px;
            cursor: pointer;
          }
        }
    
        .apps-img {
          display: block;
          width: 515px;
          height: 660px;
          margin-left: 85px;
        }
      }
    
      .blueBg {
        position: absolute;
        bottom: 0;
        margin: 0 auto;
        padding: 32px 0 33px;
        width: 100%;
        height: 127px;
        background: #2A5CE9;
        box-sizing: border-box;
        z-index: -1;
        // display: flex;
        // align-items: center;
    
        .blueBg-container {
          max-width: 1200px;
          margin: 0 auto;
          display: flex;
          align-items: center;
        margin-left:32px;
          .left {
            .zn {
              height: 33px;
              font-size: 24px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 33px;
            }
    
            .en {
              height: 25px;
              font-size: 18px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 25px;
            }
          }
    
          .right {
            display: block;
            width: 37px;
            height: 37px;
            margin-left: 144px;
          }
        }
      }
    }
    </style>
